import axios from "@axios";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getCountries", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/getOneCountry/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addCountry(ctx, countryData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/addCountry", { countryData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    updateCountry(ctx, queryParams) {
      const id = queryParams.id;
      delete queryParams.id;
      return new Promise((resolve, reject) => {
        axios
          .put(`/updateCountry/${id}`, { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateCountryStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const status = { status: queryParams.status };
        axios
          .put(`/updateCountryStatus/${queryParams.id}`, { params: status })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteCountry(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/deleteCountry/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
